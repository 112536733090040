<template>
	<router-view></router-view>
</template>

<script>
// import HelloWorld from "../components/HelloWorld.vue";
import './assets/common.css'

export default {
	name: 'App',
}
</script>

<style></style>
