export default [
	{
		img: require('@/assets/images/logo-bjdx.png'),
		name: '北京大学',
	},
	{
		img: require('@/assets/images/logo-zgkxy.png'),
		name: '中国科学院',
	},
    {
		img: require('@/assets/images/logo-qhdx.png'),
		name: '清华大学',
	},
    {
		img: require('@/assets/images/logo-nmg.png'),
		name: '内蒙古大学',
	},
    {
		img: require('@/assets/images/logo-hgd.png'),
		name: '哈尔滨工业大学',
	},
    {
		img: require('@/assets/images/logo-zgkxjs.png'),
		name: '中国科学技术大学',
	},{
		img: require('@/assets/images/logo-zghk.png'),
		name: '中国航空工业集团',
	},
    {
		img: require('@/assets/images/logo-bjlg.png'),
		name: '北京理工大学',
	},
    {
		img: require('@/assets/images/logo-bjsf.png'),
		name: '北京师范大学',
	},
    {
		img: require('@/assets/images/logo-zjdx.png'),
		name: '浙江大学',
	},
    {
		img: require('@/assets/images/logo-rmyy.png'),
		name: '北京大学人民医院',
	},
]
