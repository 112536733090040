<template>
	<!-- 平台用户 -->
	<section class="client_h5">
		<div class="client_h5_title_box">
			<div class="title_zh">平台用户</div>
			<div class="title_en">CLIENT</div>
		</div>
		<div class="client_h5_content_box">
			<div class="every_box" v-for="item in clientList" :key="item.name">
				<img :src="item.img" :alt="item.name" />
			</div>
			<div class="every_box" style="text-align:center; line-height: 40px; font-size: 26px; color: #888;">...</div>
		</div>
	</section>
</template>
<script>
import clientList from '@/utils/clientList.js'
export default {
	data() {
		return {
			clientList,
		}
	},
}
</script>
<style lang="less" scoped>
.client_h5 {
	padding: 80px 24px;
	width: 100%;
	&_title_box {
		margin: 0 0 28px;
		padding: 0;
		height: 53px;
		.title_zh {
			color: #000;
			font-weight: 600;
			font-size: 26px;
			line-height: 36px;
			opacity: 0.72;
		}
		.title_en {
			color: #000;
			font-size: 14px;
			line-height: 17px;
			opacity: 0.54;
		}
	}
	&_content_box {
		display: flex;
		flex-wrap: wrap;
		.every_box {
			margin: 0 -1px -1px 0;
			width: 50%;
			box-sizing: border-box;
			border: 1px solid #ddd;
			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}
	}
}
</style>
