<template>
	<div class="content">
		<!-- 头部LOGO -->
		<div class="header_box">
			<div class="header_icon">
				<a href="/"><img src="../assets/images/shannon-couldIcon.svg" alt="" /></a>
			</div>
			<i :class="[showMenu ? 'close-icon' : 'menu-icon', 'btn-icon']" @click="showMenu = !showMenu"></i>
		</div>
		<!-- 导航栏开始 -->
		<div :class="[showMenu ? 'hidden' : 'show']">
			<div class="content_navBox">
				<div class="nav_bar">
					<div class="bar_box">
						<ul>
							<li class="bar_txt" v-for="item in list" :key="item">
								<a href="javascript:;" @click="ScrollTo(item.name)">
									<div class="txt_item">{{ item.title }}</div>
									<span class="txt_Vertical"></span>
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div class="content_nav">
					<div class="nav_txt">
						<div class="info_zhtxt">极致性价比的</div>
						<div class="info_zhtxt">高性能计算与人工智能云服务商</div>
						<div class="info_entxt">High performance computing and artificial intelligence cloud service providers with extreme cost performance</div>
					</div>
				</div>
			</div>
			<!-- 导航栏开始结束 -->
			<!-- 平台优势栏开始 -->
			<div class="advantage_box" id="ADVANTAGE">
				<div class="advantage_title">
					<div class="tltle_zh">平台优势</div>
					<div class="title_en">ADVANTAGE</div>
				</div>
				<AdvantageH5 class="h5_advantage" @scroll="ScrollTo('#PRICE')" />
				<div class="advantage_item">
					<div class="item_top">
						<div class="top_cost">
							<div class="cost_title">高性价比</div>
							<div class="cost_low">价格低至</div>
							<div class="cost_price">
								<span class="prict_num">0.05</span>
								<span class="price_yuan">元</span>
								<span class="price_company">/核心小时</span>
							</div>
							<div class="cost_txt">
								<div class="tip_txt">极致高性价比真实按量付费</div>
								<div class="cost_btn">
									<a href="javascript:;" @click="ScrollTo('#PRICE')">
										<span>详细价格表</span>
										<img src="../assets/images/icon-prve.svg" alt="" />
									</a>
								</div>
							</div>
						</div>
						<div class="top_performance">
							<div class="performance_title">高性能</div>
							<div class="performance_txt">
								<div>全线速</div>
								<div>InfiniBand</div>
								<div>互联</div>
							</div>
							<div class="performance_info">提供物理机（不超分、非虚拟机）计算能力，采用高速低延迟的全线速InfiniBand互联</div>
						</div>
					</div>
					<div class="item_bottom">
						<div class="bottom_facilitate">
							<div class="facilitate_title">使用便利</div>
							<div class="facilitate_proce">
								<div class="proce_box" v-for="item in proce" :key="item">
									<img src="../assets/images/shannon-couldCircle.svg" alt="" />
									<div class="proce_txt">{{ item.title }}</div>
								</div>
							</div>
							<div class="proce_line"></div>
							<div class="facilitate_info">使用中遇到的所有问题请邮件联系管理员，我们会第一时间处理</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 平台优势栏结束 -->
			<!-- 面向领域栏开始 -->
			<div class="field_box" id="COVERAGE">
				<div class="field_title">
					<div class="tltle_zh">面向领域</div>
					<div class="title_en">COVERAGE</div>
				</div>
				<div class="fild_item">
					<div class="item_square">
						<div class="square_txt">
							<div class="txt_zh">高校</div>
							<div class="txt_en">Universities</div>
						</div>
						<div class="square_img">
							<img src="../assets/images/shannon-couldHouse.png" alt="" class="img_att" />
						</div>
					</div>
					<div class="item_square">
						<div class="square_txt">
							<div class="txt_zh">实验室</div>
							<div class="txt_en">laboratory</div>
						</div>
						<div class="square_img">
							<img src="../assets/images/shannon-couldVector.png" alt="" class="img_att" />
						</div>
					</div>
					<div class="item_square">
						<div class="square_txt">
							<div class="txt_zh">研究所</div>
							<div class="txt_en">Research Institute</div>
						</div>
						<div class="square_img">
							<img src="../assets/images/shannon-couldFile.png" alt="" class="img_att" />
						</div>
					</div>
				</div>
			</div>
			<!-- 面向领域栏结束 -->
			<section class="price warp1200" id="PRICE">
				<p class="mtitle">价格表</p>
				<p class="subtitle">PRICE</p>
				<p class="payType">真实按量付费：</p>
				<p class="desc">计算资源按核心每秒计费，根据实际核心使用量收费，只用一个核心则只收一个核心的费用；存储资源每日计费，使用1T存储，每日只需4元。</p>
				<table class="bluetable" border="1" cellspacing="0" cellpadding="0">
					<tr>
						<td></td>
						<td>QOS</td>
						<td>价格</td>
					</tr>
					<tr>
						<td rowspan="3">机时</td>
						<td>low</td>
						<td>0.05元/核心小时</td>
					</tr>
					<tr>
						<td>normal</td>
						<td>0.06元/核心小时</td>
					</tr>
					<tr>
						<td>high</td>
						<td>0.07元/核心小时</td>
					</tr>
					<tr>
						<td>储存</td>
						<td>hdd</td>
						<td>0.04元/10G每日</td>
					</tr>
				</table>
			</section>
			<section class="client" id="CLIENT">
				<div class="warp1200">
					<p class="mtitle">平台用户</p>
					<p class="subtitle">CLIENT</p>
					<ul>
						<li v-for="item in clientList" :key="item.name">
							<img :src="item.img" :alt="item.name" />
						</li>
						<li style="text-align:center; line-height:118px; color: #888;">...</li>
					</ul>
				</div>
				<ClientH5 class="client-h5"></ClientH5>
			</section>
			<section class="contactUs warp1200" id="CONTACT">
				<p class="mtitle">联系我们</p>
				<p class="subtitle">CONTACT</p>
				<div class="aboutUs">
					<p>
						邮箱：
						<a href="mailto:service@shannonyun.com">service@shannonyun.com</a>
					</p>
				</div>
			</section>
			<footer>
				<div class="warp1200">
					<span>
						友情链接：
						<a href="https://www.shannonai.com/" target="_black">香侬科技</a>
						<a href="https://www.mypitaya.com/" target="_black">火龙果写作</a>
						<a href="https://www.jianweidata.com/" target="_black">见微数据</a>
					</span>
					<a href="http://beian.miit.gov.cn/" target="_black" class="right">京ICP备18008438号-9</a>
				</div>
			</footer>
		</div>
		<div class="dialog_box" v-show="showMenu">
			<ul class="menu_list">
				<li v-for="item in list" @click="toMenu(item.name)" :key="item.name" class="every_list">
					{{ item.title }}
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import VueScrollTo from 'vue-scrollto'
import AdvantageH5 from '@/components/h5/AdvantageH5.vue'
import ClientH5 from '@/components/h5/ClientH5.vue'
import clientList from '@/utils/clientList.js'

export default {
	name: 'Home',
	data() {
		return {
			list: [
				{
					title: '平台优势',
					name: '#ADVANTAGE',
				},
				{
					title: '面向领域',
					name: '#COVERAGE',
				},
				{
					title: '价格表',
					name: '#PRICE',
				},
				// {
				// 	title: '解决方案',
				// },
				{
					title: '主要客户',
					name: '#CLIENT',
				},
				{
					title: '联系我们',
					name: '#CONTACT',
				},
			],
			proce: [
				{
					title: '联系管理员注册',
				},
				{
					title: '预存计算费',
				},
				{
					title: '账号开通',
				},
				{
					title: '登陆系统',
				},
				{
					title: '提交作业',
				},
				{
					title: '按计算量扣费',
				},
			],
			showMenu: false,
			clientList,
		}
	},
	components: {
		AdvantageH5,
		ClientH5,
	},
	methods: {
		ScrollTo(hash) {
			const ele = document.querySelector(hash)
			VueScrollTo.scrollTo(ele, 500, {
				container: document.querySelector('body'),
				easing: [0.4, 0.0, 0.2, 1],
			})
		},
		toMenu(name) {
			this.showMenu = false
			this.$nextTick(() => {
				this.ScrollTo(name)
			})
		},
	},
}
</script>

<style lang="less">
.font_en(@font:14px,@lineHeight:17px) {
	font-size: @font;
	line-height: @lineHeight;
	opacity: 0.54;
}
.tltle_zh() {
	font-weight: 600;
	font-size: 26px;
	line-height: 36px;
}

.header_box {
	height: 80px;
	display: flex;
	align-items: center;
	.header_icon {
		text-align: left;
		a {
			img {
				display: block;
				border: 0;
				cursor: pointer;
			}
		}
	}
}
.content_navBox {
	width: 100%;
	.nav_bar {
		height: 70px;
		background: rgba(255, 255, 255, 0.04);
		border-bottom: 1px solid rgba(255, 255, 255, 0.24);
		box-sizing: border-box;
		backdrop-filter: blur(24px);
		.bar_box {
			margin: 0 auto;
			width: 1200px;
			height: 70px;
			.bar_txt {
				text-align: center;
				display: inline-block;
				float: left;
				.txt_item {
					float: left;
					width: 112px;
					height: 70px;
					margin-left: 0;
					display: inline-block;
					font-family: PingFang SC;
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 70px;
					color: #ffffff;
				}
				.txt_item:hover {
					background-color: #1c73ff;
					font-weight: 600;
				}
				.txt_Vertical {
					display: inline-block;
					line-height: 70px;
					height: 12px;
					border: 1px solid rgba(255, 255, 255, 0.12);
					margin: 30px 8px;
				}
			}
		}
	}
	.content_nav {
		margin: 0 auto;
		// width: 1200px;
		.nav_txt {
			// width: 1200px;
			margin: 109px auto;
			.info_zhtxt {
				// width: 616px;
				text-align: left;
				font-family: PingFang SC;
				font-style: normal;
				font-weight: 600;
				// font-size: 44px;
				line-height: 62px;
				color: #fff;
				flex: none;
				order: 0;
				flex-grow: 0;
				margin: 2px 0px;
			}
			.info_entxt {
				// width: 555px;
				text-align: left;
				left: 0;
				font-family: Inter;
				font-style: normal;
				font-weight: 300;
				font-size: 18px;
				line-height: 26px;
				color: #fff;
				overflow: hidden;
				white-space: normal;
				word-break: break-all;
			}
		}
	}
}

.advantage_box {
	.advantage_title {
		// width: 1200px;
		margin: 0 auto;
		text-align: center;
		padding-top: 100px;
		.tltle_zh {
			font-family: PingFang SC;
			font-style: normal;
			font-weight: 600;
			font-size: 32px;
			line-height: 45px;
			color: rgba(0, 0, 0, 0.72);
		}
		.title_en {
			font-family: Inter;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 19px;
			text-transform: uppercase;
			color: rgba(0, 0, 0, 0.54);
		}
	}
	.advantage_item {
		// width: 1200px;
		// height: 781px;
		margin: 0 auto;
		margin-top: 48px;
		.item_top {
			height: 455px;
			display: flex;
			justify-content: space-between;
			.top_cost {
				position: relative;
				width: 584px;
				height: 455px;
				background: linear-gradient(131.02deg, #3dcded -27.29%, #0f5dda 102.61%);
				.cost_title {
					position: absolute;
					top: 48px;
					left: 48px;
					font-family: PingFang SC;
					font-style: normal;
					font-weight: 600;
					font-size: 32px;
					line-height: 45px;
					color: #ffffff;
				}
				.cost_low {
					position: absolute;
					left: 50px;
					top: 142px;
					font-family: PingFang SC;
					font-style: normal;
					font-weight: normal;
					font-size: 18px;
					line-height: 25px;
					color: #ffffff;
				}
				.cost_price {
					position: absolute;
					left: 48px;
					top: 167px;
					.prict_num {
						font-family: Inter;
						font-style: normal;
						font-weight: bold;
						font-size: 72px;
						line-height: 87px;
						color: #ffffff;
					}
					.price_yuan {
						font-family: PingFang SC;
						font-style: normal;
						font-weight: normal;
						font-size: 32px;
						line-height: 45px;
						color: #ffffff;
					}
					.price_company {
						font-family: PingFang SC;
						font-style: normal;
						font-weight: normal;
						font-size: 18px;
						line-height: 25px;
						color: #ffffff;
					}
				}
				.cost_txt {
					position: absolute;
					left: 48px;
					top: 339px;
					width: 488px;
					height: 68px;
					.tip_txt {
						display: inline-block;
						float: left;
						width: 150px;
						height: 66px;
						font-family: PingFang SC;
						font-style: normal;
						font-weight: normal;
						font-size: 24px;
						line-height: 34px;
						color: #fff;
					}
					.cost_btn {
						text-align: center;
						position: absolute;
						left: 340px;
						top: 26px;
						width: 148px;
						height: 42px;
						border: 2px solid rgba(255, 255, 255, 0.24);
						box-sizing: border-box;
						border-radius: 999px;
						font-family: PingFang SC;
						font-style: normal;
						font-weight: normal;
						font-size: 16px;
						line-height: 40px;
						color: #fff;
						a {
							color: #fff;
						}
					}
				}
			}
			.top_performance {
				position: relative;
				width: 584px;
				height: 455px;
				background: #144fae url(../assets/images/shannon-couldItem.png) no-repeat center;
				.performance_title {
					position: absolute;
					top: 48px;
					left: 48px;
					font-family: PingFang SC;
					font-style: normal;
					font-weight: 600;
					font-size: 32px;
					line-height: 45px;
					color: #ffffff;
				}
				.performance_txt {
					text-align: left;
					position: absolute;
					left: 48px;
					top: 122px;
					font-family: PingFang SC;
					font-style: normal;
					font-weight: 600;
					font-size: 45px;
					line-height: 64px;
					color: #fff;
				}
				.performance_info {
					text-align: left;
					position: absolute;
					left: 48px;
					top: 339px;
					width: 482px;
					height: 68px;
					font-family: PingFang SC;
					font-style: normal;
					font-weight: normal;
					font-size: 24px;
					line-height: 34px;
					color: #fff;
				}
			}
		}
		.item_bottom {
			height: 294px;
			margin-top: 32px;
			.bottom_facilitate {
				position: relative;
				width: 1200px;
				height: 294px;
				background: url(../assets/images/shannon-couldPrve.png) no-repeat center, linear-gradient(270deg, #1973de -8%, rgba(26, 119, 223, 0) 60.12%), #144fae;
				background-size: contain;
				.facilitate_title {
					position: absolute;
					top: 48px;
					left: 48px;
					font-family: PingFang SC;
					font-style: normal;
					font-weight: 600;
					font-size: 32px;
					line-height: 45px;
					color: #fff;
				}
				.facilitate_proce {
					position: absolute;
					top: 125px;
					left: 48px;
					width: 811px;
					height: 60px;
					display: flex;
					justify-content: space-between;
					.proce_box {
						z-index: 1;
						text-align: center;
						width: 130px;
						height: 60px;
						img {
							display: block;
							margin: auto;
						}
					}
					.proce_txt {
						font-family: PingFang SC;
						font-style: normal;
						font-weight: 600;
						font-size: 18px;
						line-height: 25px;
						text-align: center;
						color: #fff;
					}
				}
				.proce_line {
					width: 696px;
					border: 1px solid rgba(255, 255, 255, 0.24);
					position: absolute;
					left: 104px;
					top: 137px;
					z-index: 0;
				}
				.facilitate_info {
					position: absolute;
					top: 224px;
					left: 48px;
					font-family: PingFang SC;
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 22px;
					color: #fff;
				}
			}
		}
	}
}
.field_box {
	height: 562px;
	background-color: #1c73ff;
	.field_title {
		width: 1200px;
		margin: 0 auto;
		text-align: center;
		padding-top: 100px;
		.tltle_zh {
			font-family: PingFang SC;
			font-style: normal;
			font-weight: 600;
			font-size: 32px;
			line-height: 45px;
			color: #ffffff;
		}
		.title_en {
			font-family: Inter;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 19px;
			text-align: center;
			text-transform: uppercase;
			color: rgba(255, 255, 255, 0.54);
		}
	}
	.fild_item {
		width: 1200px;
		height: 250px;
		margin: 0 auto;
		margin-top: 48px;
		display: flex;
		justify-content: space-between;
		.item_square {
			position: relative;
			width: 375px;
			height: 250px;
			background: linear-gradient(294.68deg, rgba(255, 255, 255, 0.1) -11.16%, rgba(255, 255, 255, 0) 73.47%);
			border: 1px solid rgba(255, 255, 255, 0.24);
			box-sizing: border-box;
			.square_txt {
				position: absolute;
				left: 40px;
				top: 40px;
				text-align: left;
				.txt_zh {
					font-family: PingFang SC;
					font-style: normal;
					font-weight: 600;
					font-size: 24px;
					line-height: 34px;
					color: #ffffff;
				}
				.txt_en {
					font-family: Inter;
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 17px;
					text-transform: capitalize;
					color: rgba(255, 255, 255, 0.54);
				}
			}
			.square_img {
				position: absolute;
				left: 192px;
				top: 86px;
				.img_att {
					width: 132px;
					height: 132px;
				}
			}
		}
	}
}
.warp1200 {
	width: 1200px;
	margin: 0 auto;
	background: inherit;
}
.mtitle {
	font-weight: 600;
	font-size: 32px;
	line-height: 45px;
	color: rgba(0, 0, 0, 0.72);
}
.subtitle {
	font-family: Inter;
	font-size: 16px;
	line-height: 19px;
	text-transform: uppercase;
	color: rgba(0, 0, 0, 0.54);
}

.price {
	padding: 100px 0;
	height: 550px;
	box-sizing: border-box;
	position: relative;
	.payType {
		font-weight: 600;
		font-size: 18px;
		line-height: 150%;
		color: rgba(0, 0, 0, 0.72);
		margin-top: 47px;
	}
	.desc {
		font-size: 16px;
		line-height: 150%;
		color: rgba(0, 0, 0, 0.72);
		margin-top: 24px;
		width: 410px;
	}
	.bluetable {
		width: 586px;
		height: 350px;
		border: none;
		background: linear-gradient(133.46deg, #5aafff -4.55%, #1c73ff 107.16%);
		position: absolute;
		right: 0;
		top: 100px;
		td {
			border: 1px solid #fff;
			font-size: 16px;
			line-height: 70px;
			text-align: center;
			color: #fff;
		}
	}
}

.client {
	background: #f5f8fa;
	margin-bottom: 100px;
	.warp1200 {
		padding: 100px 0 136px;
	}
	ul {
		margin-top: 48px;
		display: flex;
		flex-wrap: wrap;
		li {
			margin: 0 -1px -1px 0;
			width: 33.1%;
			border: 1px solid #ddd;
			background-position: center;
			background-repeat: no-repeat;
			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}
	}
	.clientName {
		width: 100%;
		text-align: center;
		font-size: 14px;
		line-height: 20px;
		color: rgba(0, 0, 0, 0.54);
		display: block;
		padding-top: 184px;
	}
}

.contactUs {
	margin-bottom: 120px;
	text-align: left;
	.aboutUs {
		border: 1px solid rgba(0, 0, 0, 0.12);
		box-sizing: border-box;
		padding: 42px 120px;
		margin-top: 48px;
		p {
			font-size: 14px;
			line-height: 20px;
			color: rgba(0, 0, 0, 0.87);
			margin-bottom: 12px;
			padding-left: 20px;
			background-position: left center;
			background-size: 16px;
			background-repeat: no-repeat;
			background-image: url(../assets/images/icon-mail.svg);
		}
		a:hover {
			color: #1c73ff;
		}
	}
}

footer {
	height: 40px;
	line-height: 40px;
	background: #000;
	font-size: 14px;
	color: #fff;
	overflow: hidden;
	a {
		color: #fff;
		&:hover {
			color: #1c73ff;
		}
		&:visited {
			color: #fff;
		}
	}
	span {
		a {
			margin: 0 10px;
		}
	}
	.right {
		float: right;
	}
}
@media screen and (max-width: 749px) {
	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}
	.mtitle {
		width: 100%;
		.tltle_zh();
	}
	.subtitle {
		width: 100%;
		color: #000;
		.font_en();
	}
	.hidden {
		display: none;
	}
	.show {
		display: block;
	}

	.header_box {
		padding: 0 24px;
		height: 48px;
		justify-content: space-between;
		.header_icon a img {
			width: 107px;
		}
		.btn-icon {
			display: inline-block;
			width: 24px;
			height: 24px;
			cursor: pointer;
		}
		.menu-icon {
			background: url('../assets/images/menu-icon.svg') no-repeat center;
			background-size: 24px;
		}
		.close-icon {
			background: url('../assets/images/close-icon.svg') no-repeat center;
			background-size: 24px;
		}
	}

	.content_navBox {
		width: 100%;
		height: 600px;
		background: #010937 url('../assets/images/mobile-shannon-couldNav.png') no-repeat center;
		background-size: 100% auto;
		.nav_bar {
			display: none;
		}
		.content_nav {
			padding: 0 24px;
			overflow: hidden;
			.nav_txt {
				margin: 60px 0;
				.info_zhtxt {
					width: 94%;
					line-height: 42px;
					font-size: 30px;
				}
				.info_entxt {
					margin-top: 14px;
					width: 88%;
					word-break: break-word;
				}
			}
		}
	}
	// 平台优势
	.advantage_box {
		padding: 0 24px 80px;
		background: url(../assets/images/mobile-shannon-couldBgc.png) no-repeat;
		background-size: 100%;
		.advantage_title {
			padding: 60px 0 32px;
			.tltle_zh {
				.tltle_zh();
			}
			.title_en {
				color: #000;
				.font_en();
			}
		}
		.h5_advantage {
			display: block;
		}
		.advantage_item {
			display: none;
		}
	}
	// 面向领域
	.field_box {
		padding: 80px 0;
		height: auto;
		.field_title {
			padding-top: 0;
			width: 100%;
			.tltle_zh {
				.tltle_zh();
			}
			.title_en {
				color: #fff;
				.font_en();
			}
		}
		.fild_item {
			display: block;
			margin-top: 32px;
			padding: 0 24px;
			width: 100%;
			height: auto;
			.item_square {
				margin-bottom: 16px;
				width: 100%;
				height: 226px;
				.square_txt {
					left: 40px;
					top: 40px;
					.txt_zh {
						margin-bottom: 6px;
						font-size: 20px;
						line-height: 28px;
					}
					.txt_en {
						color: #fff;
						.font_en(12px, 15px);
					}
				}
				.square_img {
					height: 100px;
					right: 40px;
					bottom: 40px;
					.img_att {
						width: 100%;
						height: auto;
					}
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	// 价格表
	.price {
		padding: 80px 24px;
		width: 100%;
		height: auto;

		.payType {
			margin-top: 32px;
			font-weight: 600;
			font-size: 16px;
			line-height: 150%;
		}
		.desc {
			margin: 16px 0 24px;
			width: 100%;
			font-size: 14px;
		}
		.bluetable {
			position: static;
			width: 100%;
			height: 357px;
			border-collapse: collapse;
			table-layout: fixed;
			td {
				font-size: 14px;
				font-weight: 600;
				line-height: 20px;
			}
			tr {
				td:first-child {
					width: 22%;
					font-weight: normal;
				}
				td:nth-child(2) {
					width: 28%;
				}
			}
		}
	}
	// 平台用户
	.client {
		margin: 0;
		.warp1200 {
			display: none;
		}
		.client-h5 {
			display: block;
		}
	}
	.contactUs {
		margin: 0;
		padding: 80px 24px;
		width: 100%;
		background: url(../assets/images/contact-bg-h5.png) no-repeat center;
		background-size: 100%;
		.aboutUs {
			margin-top: 32px;
			padding: 0;
			background: none;
			border: none;
		}
	}
	footer {
		height: 98px;
		.warp1200 {
			display: flex;
			flex-direction: column;
			padding: 24px;
			width: 100%;
			line-height: 20px;
			span {
				margin-bottom: 10px;
			}
		}
	}
	.dialog_box {
		position: absolute;
		padding: 60px 24px;
		left: 0;
		top: 48px;
		bottom: 0;
		right: 0;
		background: #f5f8fa;
		overflow: auto;
		.menu_list {
			.every_list {
				font-weight: 600;
				font-size: 18px;
				color: #000;
				opacity: 0.72;
				line-height: 65px;
				cursor: pointer;
				border-bottom: 1px solid #ededed;
			}
		}
	}
}
@media screen and (min-width: 750px) {
	.header_box .header_icon {
		width: 1200px;
		margin: 0 auto;
	}
	.content_navBox {
		height: 540px;
		background: #010937 url(../assets/images/shannon-couldNav.png) no-repeat center;
		background-size: auto 540px;
		.content_nav {
			width: 1200px;
			.nav_txt {
				width: 1200px;
				margin: 109px auto;
				.info_zhtxt {
					width: 616px;
					font-size: 44px;
				}
				.info_entxt {
					width: 555px;
				}
			}
		}
	}
	.advantage_box {
		height: 1113px;
		background: url(../assets/images/shannon-couldBgc.png) no-repeat center;
		background-size: cover;
		background-color: #fff;
		.advantage_title {
			width: 1200px;
		}
		.advantage_item {
			display: block;
			width: 1200px;
			height: 781px;
		}
		.h5_advantage {
			display: none;
		}
	}
	.client {
		.warp1200 {
			display: block;
			// ul li {
			// 	&:last-child {
			// 		background-image: url(../assets/images/logo-zgkxy.png);
			// 		background-size: 200px;
			// 	}
			// 	&:first-child {
			// 		background-image: url(../assets/images/logo-bjdx.png);
			// 		background-size: 209px;
			// 	}
			// }
		}
		.client-h5 {
			display: none;
		}
	}
	.contactUs {
		.aboutUs {
			background: url(../assets/images/contact-bg.jpg) no-repeat;
			background-size: cover;
		}
	}
}
@media screen and (min-width: 750px) and (max-width: 1200px) {
	.warp1200 {
		padding-left: 32px !important;
		padding-right: 32px !important;
		box-sizing: content-box;
	}
	.bluetable {
		right: 32px !important;
	}
	.field_box,
	.advantage_item,
	.header_icon {
		min-width: 1200px;
		padding-left: 32px !important;
		padding-right: 32px !important;
		box-sizing: content-box;
	}
	.content_navBox {
		min-width: 1264px;
		.nav_bar,
		.content_nav {
			padding-left: 32px !important;
			padding-right: 32px !important;
		}
	}
}
</style>
