import { createRouter, createWebHistory } from 'vue-router'
import Home from './views/Home.vue'
// import { analyze } from '@/global/analytics'

const routes = [
	{
		path: '/',
		name: 'home',
		component: Home,
	},
	{
		path: '/:catchAll(.*)',
		name: 'notFound',
		component: Home,
	},
]

const router = createRouter({
	history: createWebHistory(),
	routes,
})

// router.afterEach(() => {
// 	analyze('pv', {})
// })

export default router
