<template>
	<section class="advantage_h5_box">
		<section class="item_box">
			<div class="item_box_title">高性价比</div>
			<div class="cost_low">价格低至</div>
			<div class="cost_price">
				<span class="cost_price_num">0.05</span>
				<span class="cost_price_yuan">元</span>
				<span class="cost_price_company">/核心小时</span>
			</div>
			<div class="cost_txt">
				<div class="tip_txt">极致高性价比真实按量付费</div>
				<div class="cost_btn">
					<a href="javascript:;" @click="$emit('scroll')">
						<span>详细价格表</span>
					</a>
				</div>
			</div>
		</section>
		<section class="item_box">
			<div class="item_box_title">高性能</div>
			<div class="performance_txt">
				<div>全线速</div>
				<div>InfiniBand</div>
				<div>互联</div>
			</div>
			<div class="performance_info">提供物理机（不超分、非虚拟机）计算能力，采用高速低延迟的全线速InfiniBand互联</div>
		</section>
		<section class="item_box">
			<div class="item_box_title">使用便利</div>
			<ul class="item_box_content">
				<li class="every_step" v-for="item in stepList" :key="item">
					<i class="every_step_icon"></i>
					<span class="every_step_title">{{ item }}</span>
				</li>
			</ul>
			<div class="item_box_footer">使用中遇到的所有问题请邮件联系管理员，我们会第一时间处理</div>
		</section>
	</section>
</template>
<script>
export default {
	data() {
		return {
			stepList: ['联系管理员注册', '预存计算费', '帐号开通', '登录系统', '提交作业', '按计算量扣费'],
		}
	},
}
</script>
<style lang="less" scoped>
.advantage_h5_box {
	.item_box {
		margin-bottom: 16px;
		padding: 40px 24px;
		width: 100%;
		.item_box_title {
			font-weight: 600;
			font-size: 26px;
			line-height: 36px;
		}
		&:first-child {
			padding-bottom: 46px;
			color: #fff;
			background: linear-gradient(131.02deg, #3dcded -27.29%, #0f5dda 102.61%);
			.cost_low {
				margin: 24px 0 4px;
				font-size: 14px;
				line-height: 20px;
			}
			.cost_price {
				margin-bottom: 35px;
				&_num {
					font-weight: bold;
					font-family: Inter;
					font-size: 56px;
					line-height: 68px;
				}
				&_yuan {
					margin: 0 10px;
					font-size: 24px;
					line-height: 34px;
				}
				&_company {
					font-size: 14px;
					line-height: 20px;
				}
			}
			.cost_txt {
				display: flex;
				align-items: flex-end;
				.tip_txt {
					width: 110px;
					margin-right: 32px;
					font-size: 18px;
					line-height: 25px;
				}
				.cost_btn {
					display: flex;
					width: 136px;
					height: 40px;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					border: 2px solid rgba(255, 255, 255, 0.24);
					border-radius: 999px;
					span {
						color: #fff;
						&::after {
							display: inline-block;
							margin-left: 4px;
							content: '';
							width: 14px;
							height: 10px;
							background: url(../../assets/images/icon-prve.svg) no-repeat center;
							background-size: 10px;
						}
					}
				}
			}
		}
		&:nth-child(2) {
			color: #fff;
			background: #144fae url(../../assets/images/shannon-couldItem-h5.png) no-repeat center;
			.performance_txt {
				margin: 24px 0;
				font-weight: 600;
				font-size: 30px;
				line-height: 42px;
			}
			.performance_info {
				font-size: 18px;
				line-height: 25px;
			}
		}
		&:nth-child(3) {
			margin-bottom: 0;
			position: relative;
			padding-bottom: 83px;
			color: #fff;
			background: linear-gradient(270deg, #1973de -8%, rgba(26, 119, 223, 0) 60.12%), #144fae;
			.item_box_content {
				position: relative;
				display: inline-block;
				margin: 14px auto 8px;
				font-weight: 600;
				font-size: 14px;
				line-height: 20px;
				transform: translate(50%);
				.every_step {
					display: flex;
					margin-bottom: 18px;
					height: 50px;
					align-items: center;
					&_icon {
						position: relative;
						display: inline-block;
						margin-right: 12px;
						width: 20px;
						height: 20px;
						background: #1c73ff;
						border: 4px solid #ffffff;
						border-radius: 50%;
					}
					&:last-child {
						margin-bottom: 0;
					}
				}
				&::before {
					position: absolute;
					display: inline-block;
					width: 1px;
					left: 9px;
					top: 25px;
					bottom: 25px;
					content: '';
					background: rgba(255, 255, 255, 0.24);
				}
			}
			.item_box_footer {
				font-size: 12px;
				line-height: 17px;
			}
			&::after {
				position: absolute;
				content: '';
				width: 100%;
				height: 60px;
				left: 0;
				bottom: 0;
				background: url(../../assets/images/advantage-footer-h5.png) no-repeat center bottom, linear-gradient(0deg, #c9d4e6, #c9d4e6);
				background-size: 100% 54px;
			}
		}
	}
}
</style>